import './App.css'

import React, { useState, useEffect } from 'react'

import { useAuth0 } from '@auth0/auth0-react'

const url =
    'https://00ro36818a.execute-api.us-east-1.amazonaws.com/default/cc-get-events'

const padNumber = (num) => {
    if (num < 10) {
        return `0${num}`
    }

    return num
}

function App() {
    const [myEvents, setEvents] = useState(null)

    // auth0
    const { user, isAuthenticated, getAccessTokenSilently, loginWithRedirect } =
        useAuth0()

    useEffect(() => {
        const getData = async () => {
            console.log('get data')
            console.log(isAuthenticated)
            console.log(myEvents)

            try {
                console.log('trying')
                const accessToken = await getAccessTokenSilently({
                    audience: `https://auth0-jwt-authorizer`,
                    scope: 'read:current_user',
                })
                console.log('again')
                const myDat = await fetch(url, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'content-type': 'application/json',
                    },
                })

                const data = await myDat.json()

                console.log(data)
                setEvents(data)
            } catch {
                console.log('error')
            }
        }

        getData()
    }, [getAccessTokenSilently])

    const getLastStatus = () => {
        if (myEvents && !!myEvents.lastScan) {
            const lastItem = myEvents.lastScan
            const lastDateTime = new Date(lastItem.timestamp.replace(/-/g, '/'))

            return (
                <>
                    <p>
                        Last checked by {lastItem.userId} at{' '}
                        {padNumber(lastDateTime.getHours())}:
                        {padNumber(lastDateTime.getMinutes())}
                    </p>
                    <img src="tick.png" alt="" />
                </>
            )
        } else {
            return <p>No events recorded today</p>
        }
    }

    const getStatus = (item) => {
        if (item.events.length > 0) {
            return `OK`
        } else if (item.time < new Date().getUTCHours()) {
            return 'MISSING'
        } else {
            return 'PENDING'
        }
    }

    const getLogin = () => {
        console.log('get login ')
        if (isAuthenticated) {
            console.log('user:')
            console.log(user)
            return <span className="loginStatus">Logged in as {user.name}</span>
        } else {
            return <button onClick={() => loginWithRedirect()}>Log In</button>
        }
    }

    return (
        <div className="App">
            <header className="App-header">
                <img
                    src="cc-intel-white.png"
                    className="headingLogo"
                    alt="Cleancore intelligence - automatically track and manage cleaning activity"
                />
                <img
                    src="fm-logo.png"
                    className="fmLogo"
                    alt="your company logo"
                />
            </header>

            <main>
                <div className="statusSegment">
                    <div className="innerSegment">
                        {getLogin()}
                        {getLastStatus()}
                    </div>
                </div>
                <div className="mainSegment">
                    <div className="innerSegment">
                        <div className="locationHeading">
                            <h3>Location: Blue Fin Building</h3>
                            <p>Location is compliant</p>
                            <button className="cta">Report an issue</button>
                        </div>
                        <div className="time">
                            <span className="timeIndicator">12:07</span>
                            <span className="dateIndicator">Tue 24 May</span>
                        </div>
                        <div className="weather">
                            <span>23&deg;C</span>
                            <span>London</span>
                        </div>
                    </div>
                </div>
                <div className="eventsSegment">
                    <h3>Today's cleaning schedule</h3>

                    <table>
                        <tbody>
                            <tr>
                                {myEvents &&
                                    myEvents.freq.map((item, index) => {
                                        const status = getStatus(item)
                                        const lastTime = new Date(
                                            item.events[0] &&
                                                item.events[0].timestamp.replace(
                                                    /-/g,
                                                    '/'
                                                )
                                        )

                                        return (
                                            <td
                                                key={index}
                                                className={`status${status}`}
                                            >
                                                <span>
                                                    {padNumber(item.time)}
                                                    :00 -{' '}
                                                    {padNumber(item.time + 2)}
                                                    :00
                                                </span>
                                                <span className="statusIndicator">
                                                    {status}
                                                </span>
                                                <div className="bottom">
                                                    <span>
                                                        {status === 'OK' &&
                                                            `Checked at ${padNumber(
                                                                lastTime.getHours()
                                                            )}:${padNumber(
                                                                lastTime.getMinutes()
                                                            )}`}
                                                    </span>
                                                    <span>
                                                        {item.events[0]
                                                            ? `Operative: ${item.events[0].userId}`
                                                            : ''}
                                                    </span>
                                                    {item.events.length > 1 && (
                                                        <span>
                                                            {`+ ${
                                                                item.events
                                                                    .length - 1
                                                            } more`}
                                                        </span>
                                                    )}
                                                </div>
                                            </td>
                                        )
                                    })}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </main>
        </div>
    )
}

export default App
